/* Font Families:
    font-family: 'Source Sans Pro', sans-serif;  HEADERS


	font-family: 'Barlow', sans-serif;  PARAGRAPHS and other small text
	font-family: 'Lato', sans-serif; Nav Links
	font-family: 'Monoton', cursive; TD logo
*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Lato&family=Monoton&display=swap");

/* Custom Styling */

.headerText {
	font-family: "Source Sans Pro", sans-serif !important;
	padding: 10px;
}

.navBar {
	background-color: #519872 !important;
    color: #ffffff !important;
	padding: 0 !important;
}

.cardButton {
	position: absolute;
	bottom: 15px;
	left: 24%;
}
.expandedCon {
  height: 350px;
  font-family: 'Caudex', serif;
}

.cardButtonCon {
	position: absolute;
	bottom: 75px;
	left: 0;
}

.cardButtons {
	padding: 5px 10px !important;
	width: 130px;
    text-align: center;
}

.headshot {
	height: 100%;
	width: 30%;
	border-radius: 50px;
	min-width: 150px;
	max-width: 225px;
	outline: solid 2px white;
	outline-offset: 10px;
}

.textBox {
	padding: 15px;
	margin-top: 10px !important;
	width: 65%;
	min-width: 255px;
	max-width: 600px;
	border-left: solid 2px #fff;
    border-right: solid 2px #FFF;
    border-radius: 2%;
}
#top{
	font-family: 'Monoton', cursive;
	font-size: 24px;
}

@media only screen and (max-width: 600px) {
	.textBox {
		border-left: none;
		border-right: none;
		border-radius: 0;
	}
}

/* css animations */

/* Grow */
.hvr-grow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-property: transform;
	transition-property: transform;
	padding: 5px;
	cursor: pointer;
	font-size: 16px;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	text-decoration: none !important;
}

/* Float */
.hvr-float {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	background-color: #519872;
	color: #fff !important;
	font-weight: 300;
	font-size: 16px;
	letter-spacing: 1.5px;
	padding: 10px 25px;
	border-radius: 5px;
	font-family: 'Lato', sans-serif;
	cursor: pointer;
}
.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
	background-color: #3B5249;
	text-decoration: none !important;
}
